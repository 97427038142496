import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_URL } from '../shared.constants';
import { Header } from './header';
import { Ticket } from '../shared.types';
import { Contacts } from './contacts';

export const VerifyTicket = () => {
  const { ticketCode } = useParams() as { ticketCode: string };

  const [isLoading, setIsLoading] = useState(true);
  const [ticket, setTicket] = useState<Ticket>();

  useEffect(() => {
    setIsLoading(true);
    fetch(API_URL + `/ticket/public/${ticketCode}`)
      .then((response) => response.json())
      .then((response) => {
        setTicket(response.ticket);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ticketCode]);

  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const [resolved, setResolved] = useState(false);

  const handleSubmit = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const payload = {
      name: userName,
      phone: userPhone,
    };

    fetch(API_URL + `/ticket/public/${ticketCode}/resolve`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setResolved(true);
        } else {
          alert("Купон не найден или исчерпан");
        }
      });
  };

  return (
    <div style={{ margin: '0 auto', padding: '0 32px', maxWidth: '1024px', textAlign: 'center' }}>
      <Header />
      {resolved ? (
        <div>
          <b style={{ color: 'green' }}>Заявка отправлена, ждите ваш Приз!</b>
        </div>
      ) : <>
        {!isLoading ? (
          <>
            {ticket?.winning ? (
              <div>
                <b style={{ color: 'green' }}>Поздравляю, вы выиграли Приз!</b><br /><br />
                Пропишите Ваши данные для отправки приза по прописанному вами номеру!<br /><br />
                <div style={{ textAlign: 'initial' }}>
                  <form onSubmit={handleSubmit} style={{ margin: '20px auto', width: '320px' }}>
                    <label>Имя:</label><br/>
                    <input
                      className='text-input'
                      type={'text'}
                      value={userName}
                      required
                      onChange={(event) => setUserName(event.target.value)}
                    />

                    <br/><br/>

                    <label>Телефон:</label><br/>
                    <input
                      className='text-input'
                      type={'tel'}
                      value={userPhone}
                      required
                      onChange={(event) => setUserPhone(event.target.value)}
                    />

                    <br/><br/>

                    <button className='zn-button' type={'submit'}>
                      Отправить
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <b style={{ color: 'red' }}>
                Купон не выигрышный или исчерпан!
              </b>
            )}
          </>
        ) : 'Загрузка...'}
      </>}

      <br />
      <br /><br /><br />

      <Contacts />
    </div>
  );
};
