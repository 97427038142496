import { Header } from './header';
import { Contacts } from './contacts';

export const Home = () => {
  return (
    <div style={{ margin: '0 auto', padding: '0 32px', maxWidth: '1024px' }}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '40px' }}>
        <Header />
        <p style={{ textAlign: 'center', fontSize: '18px' }}>
          Откройте для себя ассортимент семян премиум-класса в "Золотая Несушка". Наслаждайтесь нашими жареными семечками подсолнуха, богатыми белком тыквенными семечками и питательными семенами чиа.<br/><br/>
          Поднимите свой уровень перекусов вкусными и полезными вариантами. Начните питать свое тело сегодня!
        </p>
        <br />
        <br />

        <Contacts />
      </div>
    </div>
  );
};
