import logoImg from '../../assets/logo.jpg';

export const Header = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '40px' }}>
      <img src={logoImg} alt={'Золотая Несушка'} height={'260px'} style={{ marginBottom: '20px' }} />

      <h3>Золотая Несушка: Здоровые закуски с призами!</h3>
    </div>
  );
};
