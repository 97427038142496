export const Contacts = () => {
  return (
    <>
      <h3>Контакты</h3>
      <p style={{ textAlign: 'center' }}>
        Адрес:<br />
        123 NutriSeed Street,<br />
        Healthyville, USA.<br />
        <br />
        Эл-почта: info@nutriseeds.com<br />
        Телефон: +1 (123) 456-7890
      </p>
    </>
  );
};
